<template>
    <div class="content">
        <br />
        <a-card v-for="(item, index) in dataSource" :key="index" class="list-card" @click="handleExpend(index)">
            <span slot="title">
                {{ item.name }}&emsp;&emsp;
                <span class="list-area">
                    <a-icon type="environment" />
                    {{ item.area }}
                </span>
            </span>
            <a slot="extra" v-show="dataIdList.includes(index) === false">
                职位详情&ensp;
                <a-icon type="right" />
            </a>
            <a slot="extra" v-show="dataIdList.includes(index) === true">
                收起详情&ensp;
                <a-icon type="down" />
            </a>
            <div v-if="dataIdList.indexOf(index) !== -1" v-html="item.text" class="list-text" />
            <font v-if="dataIdList.indexOf(index) !== -1" color="#409EFF">
                <br />
                <br />
                简历发送至&ensp;cheertech@dataant.com.cn
            </font>
        </a-card>
    </div>
</template>

<script>
export default {
    data() {
        return {
            dataIdList: [],
            dataSource: [
                {
                    name: '前端开发工程师(中级/初级)',
                    area: '上海,南京',
                    text: `
                        <strong>[职责描述]</strong><br/>
                        1. 负责公司ToB业务项目开发，包括Web应用和数据大屏等多个方向<br/>
                        2. 负责前端组件开发和项目维护<br/>
                        3. 参与团队前端工程化体系建设，规范研发流程和研发效率<br/>
                        3. 与产品经理、设计师和后端工程师一起，提升产品的用户体验<br/>
                        <strong>[任职要求]</strong><br/>
                        1. 深入了解HTML/CSS3/ES6+等前端基础技能，了解Web应用的基本实现原理，掌握HTTP等基本协议<br/>
                        2. 熟练使用一种流行的前端框架，例如：vue/react/angular及其全家桶，有过实际项目经验<br/>
                        3. 深入理解前端组件化开发，对于mvp、mvc和mvvm有自己的理解<br/>
                        4. 熟练使用webpack，glup等前端构建技术<br/>
                        5. 解决前端遇到的各种技术、性能和兼容性问题<br/>
                        6. 在理解业务的前提下，持续优化前端用户体验和页面响应速度<br/>
                        7. 良好的开发习惯和代码规范，追求高质量代码，熟练使用Git及其多人协同开发<br/>
                        8. 自我驱动，学习能力强，具有良好的沟通能力和团队合作精神<br/>
                        <strong>[加分项]</strong><br/>
                        1. 有大屏/可视化经验的优先<br/>
                        2. 有云平台经验的优先`
                },
                {
                    name: 'Java开发工程师(中级/初级)',
                    area: '上海,南京',
                    text: `
                    <strong>[职责描述]</strong><br/>
                    1. 根据产品需求，参与JAVA后台软件设计，开发与调试等工作<br/>
                    2. 参与需求分析、系统设计以及核心代码的编写<br/>
                    3. 能按照项目计划，按时提交高质量的代码，单元测试工作，完成开发任务<br/>
                    <strong>[任职要求]</strong><br/>
                    1. 3年以上J2EE项目开发经验；熟悉Java及Web的开发和应用<br/>
                    2. 具有对多线程,IO,网络等方面的编程能力，对jvm有一定的了解<br/>
                    3. 熟练使用Spring,Mybatis等常用开发框架，主要的开发框架了解其原理<br/>
                    4. 熟练使用Spring Boot,熟悉Spring Cloud,有实际开发经验优先<br/>
                    5. 熟练掌握主流关系型数据库（如Oracle、MySQL等），熟练使用SQL,有优化经验优先<br/>
                    6. 熟练掌握非关系型数据库，redis、mongo等<br/>
                    7. 熟悉常用的中间件,如kafka等<br/>
                    8. 学习和理解能力强，具备一定的系统分析能力以及独立解决问题的能力<br/>
                    <strong>[加分项]</strong><br/>
                    1. 有分布式开发经验<br/>
                    2. 熟悉其他一门语言如 go，python等<br/>
                    3. 有从0到1的项目开发经验
                    `
                },
                {
                    name: 'Golang开发工程师(中级/初级)',
                    area: '上海,南京',
                    text: `
                        <strong>[职责描述]</strong><br/>
                        1. 负责运维系统后端系统开发框架的规划、搭建和改进工作<br/>
                        2. 攻克技术难点，持续提升核心系统在高并发场景下的稳定性和性能<br/>
                        3. 负责Go相关框架与中间件的调研、维护与开发工作<br/>
                        4. 研究Go相关的前沿技术，并能够转化到实际应用当中<br/>
                        <strong>[任职要求]</strong><br/>
                        1. 3年以上golang后端工作经验或1年以上微服务架构设计经验<br/>
                        2. 熟悉Go语言，了解Goroutine设计原理、GMP调度原理与Runtime<br/>
                        3. 掌握代码设计模式和思想，有优秀的抽象能力和编码能力，有良好的编程风格<br/>
                        4. 了解docker容器化，并熟悉容器化编排服务k8s、k8s工作负载等<br/>
                        5. 精通高并发、高可用，有分布式服务治理、容量、容灾等经验<br/>
                        6. 熟悉常用的框架及开源中间件，并有过实际的应用和调优经验<br/>
                        7. 熟悉TCP、HTTP、gRPC等常用的网络协议<br/>
                        <strong>[加分项]</strong><br/>
                        1. 有运维系统开发经验优先<br/>
                        2. 有开源项目贡献优先<br/>
                        3. 有容器开发经验优先
                    `
                },
                {
                    name: '运维专家(资深/高级)',
                    area: '上海',
                    text: `
                        <strong>[职责描述]</strong><br/>
                        1. 负责生产应用的运维建设，提高服务稳定性和用户体验<br/>
                        2. 负责基础运维工作、提高自动化运维水平、故障响应能力、优化资源使用率<br/>
                        3. 优化线上技术架构，从运维角度参与并推动研发、产品改进架构体系<br/>
                        4. 参与平台的建设，从提升开发效率、降低运维人力成本<br/>
                        5. 运维相关的新技术的研究<br/>
                        <strong>[任职要求]</strong><br/>
                        1. 计算机相关专业，大学本科及以上学历，5年以上工作经验<br/>
                        2. 对Linux操作系统原理有深入的理解，熟悉 TCP/IP、HTTP以及常用RPC协议识<br/>
                        3. 掌握以下中间件服务配置和管理(Redis/MongoDB/Zookeeper/RockerMQ/Nginx/Tomcat等)<br/>
                        4. 掌握大数据平台的维护和管理(Spark/HDFS/Kafka等)<br/>
                        5. 掌握使用 FastDSF 文件系统<br/>
                        6. 掌握主流监控系统的使用和管理(Zabbix/Prometheus)<br/>
                        7. 有较强的故障定位和排查能力<br/>
                        8. 良好的沟通、组织协调、项目管理能力和强烈的责任心<br/>
                        9. 至少熟悉一种开发语言(python/shell/golang)<br/>
                    `
                },
                {
                    name: '运维工程师(中级)',
                    area: '上海',
                    text: `
                        <strong>[职责描述]</strong><br/>
                        1. 负责中间件的标准化、平台化和服务化<br/>
                        2. 负责中间件的维护，配置，使用规范等<br/>
                        3. 定位并解决生产环境遇到的中间件相关问题<br/>
                        <strong>[任职要求]</strong><br/>
                        1. 2~3 年中间件相关工作经验，计算机相关专业专科及以上学历<br/>
                        2. 具有强烈的责任感、良好的沟通能力和服务意识<br/>
                        3. 熟悉互联网中间件平台的架构，熟悉微服务框架、分布式组件、高并发高可用系统，并 有丰富的实战经验<br/>
                        4. 熟悉 Linux 常用操作，了解 K8S、docker 等容器化技术<br/>
                        5. 熟悉 web 类中间件，如 nginx/tomcat/apache/weblogic 等<br/>
                        6. 熟悉 jvm 常用参数及调优，了解微服务架构<br/>
                        7. 熟悉常用数据库技术，如 mysql/redis/mongdb 等<br/>
                        8. 熟悉常用消息中间件，如 kafka/MQ 等
                    `
                },
                {
                    name: '售前技术支持(中级/初级)',
                    area: '上海',
                    text: `
                        <strong>[职责描述]</strong><br/>
                        1. 为销售团队提供技术支持，协助销售人员为客户演示产品，与客户面对面沟通、交流技术细节<br/>
                        2. 深入分析客户需求，与销售团队合作为客户制定产品的使用方案，并撰写必要的技术文档<br/>
                        3. 负责产品的 POC、售前文档、产品培训等工作<br/>
                        4. 准确地将客户需求传达给产品研发团队，并进行必要的整理和归纳<br/>
                        5. 为客户提供技术支持，解决客户在使用产品时遇到的问题<br/>
                        <strong>[任职要求]</strong><br/>
                        1. 本科或本科以上学历，计算机相关专业<br/>
                        2. 有面向客户现场支持经验<br/>
                        3. 有运维/容器/私有云/混合云其中一种行业经验优先<br/>
                        4. 具有良好的文字写字能力、语言表达能力和客户沟通能力<br/>
                        5. 快速学习能力;主动性强
                    `
                }
            ]
        }
    },
    methods: {
        // 展开/收起职位详情
        handleExpend(index) {
            let ids = this.dataIdList
            if (ids.includes(index)) {
                ids.splice(ids.indexOf(index), 1)
            } else {
                ids.push(index)
            }
            this.dataIdList = ids
        }
    }
}
</script>

<style scoped>
.content {
    width: 80%;
    margin: 0 auto;
}
.list-card {
    margin-top: 20px;
    margin-bottom: 20px;
}
.list-card:hover {
    cursor: pointer;
    box-shadow: rgb(175 175 175 / 50%) 0px 2px 10px 0px;
}
.list-area {
    font-size: 14px;
    color: #333333;
}
/* 列表内容 */
.list-text {
    line-height: 30px;
}
</style>
